.footer4-footer7 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer4-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer4-content {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer4-logo1 {
  gap: 24px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.footer4-logo2 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
}
.footer4-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
}
.footer4-link1 {
  text-decoration: none;
}
.footer4-link2 {
  text-decoration: none;
}
.footer4-credits {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.footer4-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.footer4-container {
  display: flex;
  align-items: flex-start;
}
.footer4-footer-links {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.footer4-text13 {
  text-decoration: none;
}
.footer4-text14 {
  text-decoration: none;
}
.footer4-text16 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
}
.footer4-text17 {
  display: inline-block;
}
.footer4-text18 {
  display: inline-block;
}
.footer4-text22 {
  display: inline-block;
}
.footer4-text23 {
  display: inline-block;
}
