.home-container {
  width: 100%;
  display: flex;
  z-index: 100;
  min-height: 100vh;
  flex-direction: column;
}
.home-text10 {
  display: inline-block;
}
.home-text11 {
  display: inline-block;
}
.home-text12 {
  display: inline-block;
  text-align: center;
}
.home-text13 {
  display: inline-block;
  text-align: center;
}
.home-text14 {
  display: inline-block;
}
.home-text15 {
  display: inline-block;
}
.home-text16 {
  display: inline-block;
}
.home-text17 {
  display: inline-block;
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  display: inline-block;
}
.home-text20 {
  display: inline-block;
}
.home-text21 {
  display: inline-block;
}
.home-text22 {
  display: inline-block;
}
.home-text23 {
  display: inline-block;
}
.home-text24 {
  display: inline-block;
}
.home-text25 {
  display: inline-block;
}
.home-text26 {
  display: inline-block;
}
.home-text27 {
  display: inline-block;
  text-align: center;
}
.home-text28 {
  display: inline-block;
  text-align: center;
}
.home-text29 {
  display: inline-block;
  text-align: center;
}
.home-text30 {
  display: inline-block;
  text-align: center;
}
.home-text31 {
  display: inline-block;
  text-align: left;
}
.home-text32 {
  display: inline-block;
  text-align: left;
}
.home-text33 {
  display: inline-block;
  text-align: left;
}
.home-text34 {
  display: inline-block;
  text-align: left;
}
.home-text35 {
  display: inline-block;
  text-align: center;
}
.home-text36 {
  display: inline-block;
}
.home-text37 {
  display: inline-block;
}
.home-text38 {
  display: inline-block;
}
.home-text39 {
  display: inline-block;
}
.home-text40 {
  display: inline-block;
}
.home-text41 {
  display: inline-block;
}
.home-text42 {
  display: inline-block;
}
.home-text43 {
  display: inline-block;
}
.home-text44 {
  display: inline-block;
}
.home-text45 {
  display: inline-block;
}
.home-text46 {
  display: inline-block;
}
.home-text49 {
  display: inline-block;
}
.home-text50 {
  display: inline-block;
}
.home-text51 {
  display: inline-block;
  text-align: center;
}
.home-text54 {
  display: inline-block;
  text-align: center;
}
.home-text55 {
  display: inline-block;
  text-align: center;
}
.home-text59 {
  display: inline-block;
}
.home-text63 {
  display: inline-block;
}
.home-text64 {
  display: inline-block;
}
.home-text65 {
  display: inline-block;
}
.home-text66 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
}
@media(max-width: 471px) {
  .home-container {
    width: calc(100% - 40px);
    z-index: 100;
    font-size: 2vw;
    max-width: 100%;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .home-text12 {
    font-size: larger;
    max-width: 100%;
  }
  .home-text13 {
    font-size: x-large;
    max-width: 100%;
  }
}
