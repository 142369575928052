.contact1-contact20 {
  gap: var(--dl-space-space-threeunits);
  height: 167px;
  display: flex;
  overflow: hidden;
  position: relative;
  padding-top: 0px;
  flex-direction: column;
}
.contact1-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.contact1-link1 {
  display: contents;
}
.contact1-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
.contact1-contact-info1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-text10 {
  align-self: stretch;
  text-align: center;
}
.contact1-link2 {
  display: contents;
}
.contact1-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
.contact1-icon3 {
  width: 67px;
  height: 56px;
}
.contact1-contact-info2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-content4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-text11 {
  align-self: stretch;
  text-align: center;
}
.contact1-link3 {
  display: contents;
}
.contact1-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  text-decoration: none;
}
.contact1-contact-info3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-content6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact1-text12 {
  align-self: stretch;
  text-align: center;
}
.contact1-text15 {
  display: inline-block;
  text-align: center;
}
.contact1-text18 {
  display: inline-block;
  text-align: center;
}
.contact1-text19 {
  display: inline-block;
  text-align: center;
}
